<template>
  <section
    class=""
    style="padding: 80px 15px 15px 145px; padding-top: 180px; background-color: transparent"
  >
    <div>
      <div
        class=" all"
        style="margin-top: 0px"
      >
        <div class="information">
          <div class="infoo">
            <!-- <h1>Setzen Sie sich mit unserem Team in Verbindung!</h1> -->
            <div>
              <div>
                <div>
                  <div class="biphoto">
                    <img
                      src="@/assets/images/digiti2.svg"
                      alt=""
                    >
                  </div>
                </div>
                <div class="container texti">
                  <h1>DigIT Consulting</h1>
                  <p>
                    At DigIT Consulting, we're more than a SaaS platform; we're your partner in success.
                    Our mission is to provide innovative tools and solutions that transform your business.
                    We prioritize simplicity, reliability, and a customer-centric approach, ensuring your business thrives in the digital age.
                    Trust us to transform the way you work.
                  </p>
                </div>


                <div
                  class="container info"
                >
                  <b-icon
                    style="color: black"
                    class="mailIcon"
                    icon="envelope-fill"
                    aria-hidden="true"
                  />
                  <p>
                    <a
                      style="text-decoration: none; color: black"
                      href="mailto:contacts@digitbusiness.ch"
                    >contacts@digitbusiness.ch</a>
                  </p>
                </div>
              </div>
              <div>

                <div
                  class="container street"
                >
                  <b-icon
                    class="mapIcon"
                    icon="link"
                    aria-hidden="true"
                  />
                  <p>
                    <a
                      style="text-decoration: none; color: black"
                      href="https://www.digitbusiness.ch/"
                      target="_blank"
                    >https://www.digitbusiness.ch/</a>
                  </p>
                </div>
              </div>


              <div
                class="container phone"
              >
                <b-icon
                  class="phoneIcon"
                  icon="telephone-fill"
                  aria-hidden="true"
                />
                <p>
                  <a
                    style="text-decoration: none; color: black; margin-right: 15px;"
                    href="tel:+41 79 336 31 36"
                  >CH: +41 79 336 31 36</a>
                </p>
                <p>
                  <a
                    style="text-decoration: none; color: black"
                    href="tel:+383 49 999 433"
                  >XK: +383 49 999 433</a>
                </p>
              </div>
              <div
                class="container phone"
              >
                <b-icon
                  class="phoneIcon"
                  icon="globe"
                  aria-hidden="true"
                />
                <p>
                  <a
                    style="text-decoration: none; color: black; margin-right: 5px;"
                    href="https://www.google.com/maps/place/DigIT+Consulting+GmbH/@47.3664979,8.5179592,17z/data=!3m1!4b1!4m6!3m5!1s0x479009e402ed904b:0x64f06f7a3f8075c4!8m2!3d47.3664943!4d8.5205341!16s%2Fg%2F11t314x8nq?entry=ttu"
                    target="_blank"
                  >
                    HQ - Steinstrasse 56, 8003 Zürich</a>
                </p>
                <p>
                  <a
                    style="text-decoration: none; color: black"
                    href="https://www.google.com/maps/place/28a+Rexhep+Mala,+Prishtina+10000/@42.6550361,21.1626988,17z/data=!3m1!4b1!4m5!3m4!1s0x13549ee804a7a7e5:0x9605bc885cd33733!8m2!3d42.6550322!4d21.1648875?shorturl=1"
                    target="_blank"
                  >
                    Branch - R. Mala, 28A, Prishtinë</a>
                </p>
              </div>

            </div>

          </div>
          <div class="HR">
            <div class="hr" />

          </div>
          <div class="infoo">
            <!-- <h1>Setzen Sie sich mit unserem Team in Verbindung!</h1> -->
            <div>
              <div>
                <div>
                  <div class="biphoto">
                    <img
                      src="@/assets/images/Walone.svg"
                      alt=""
                    >
                  </div>
                </div>
                <div class="container texti">
                  <h1>Walone FASHION GROUP L.L.C.</h1>
                  <p>
                    Walone Fashion Group, based in Kosovo, make male and female apparel and have garnered a great reputation across Europe.
                    Whilst these are gowns that every lady will admire, it is worthy to note that the brand makes every clothing item.
                    Their gowns have been worn by Albanian singers and superstars across the continent.
                    They create a slew of wonderful dresses regularly, as such, their style pushes barriers and is continually funky.
                  </p>
                </div>


                <div>

                  <div
                    class="container info"
                  >
                    <b-icon
                      style="color: black"
                      class="mailIcon"
                      icon="envelope-fill"
                      aria-hidden="true"
                    />
                    <p>
                      <a
                        style="text-decoration: none; color: black"
                        href="mailto:walonefashion@outlook.com"
                      >walonefashion@outlook.com</a>
                    </p>
                  </div>
                </div>
                <div>


                  <div
                    class="container street"
                  >
                    <b-icon
                      class="mapIcon"
                      icon="link"
                      aria-hidden="true"
                    />
                    <p>
                      <a
                        style="text-decoration: none; color: black"
                        href="https://walonefashion.com/"
                        target="_blank"
                      >https://walonefashion.com/</a>
                    </p>
                  </div>
                </div>
                <div
                  class="container phone"
                >
                  <b-icon
                    class="phoneIcon"
                    icon="telephone-fill"
                    aria-hidden="true"
                  />
                  <p>
                    <a
                      style="text-decoration: none; color: black"
                      href="tel:+38349200440"
                    >+383 49 200 440</a>
                  </p>
                </div>
                <div
                  class="container phone"
                >
                  <b-icon
                    class="phoneIcon"
                    icon="globe"
                    aria-hidden="true"
                  />
                  <p>
                    <a
                      style="text-decoration: none; color: black"
                      href="https://maps.app.goo.gl/azzc1eHdsspWmkb18"
                      target="_blank"
                    >Çagllavicë Magjistralja PRISHTINË-FERIZAJ, KM7, 10000, Prishtina, Kosovo</a>
                  </p>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      // isValdrin: false,
    }
  },
  mounted() {
    // if (window.location.href == 'https://www.valdrinsahiti.spim.app/info') {
    //   this.isValdrin = true
    // } else {
    //   this.isValdrin = false
    // }
  },
}
</script>

  <style scoped>
  .hr{
    min-width: 1px;
    height: 850px;
    background-color: grey;
  }
  .hrr{
    max-width: 1500px;
    height: 1px;
    background-color: grey;
  }

  img{
    max-width: 300px;
    min-height: 221px;
  }
  .biphoto{
  /* background-color: white; */
  width: 100%;
  min-height: 90px;
  }
  .b-icon.bi {
    display: inline-block;
    overflow: visible;
    vertical-align: -0.15em;
    margin-top: 7px;
    width: 25px;
    height: 25px;
  }
  .social-icons a {
    display: block;
    width: 40px;
    height: 40px;
    /* height: 24px; */
    border-radius: 0;
    background-color: white;
    text-align: left;
    line-height: 24px;
  }
  .social-icons {
    display: flex;
    justify-content: space-between;
    width: 75%;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    /* margin-left: 20px; */
  }
  .iconat {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    /* justify-content: space-between; */
  }
  .texti {
    padding-top: 20px;
    padding-left: 5px;
  }
  .texti p {
    max-width: 540px;
    min-height: 192px;
    text-align: left;
    font: normal normal normal 22px/32px ;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
  }
  .street {
    display: flex;
    margin-top: 25px;
    background-color: lightgray;
  }
  .info {
    display: flex;
    margin-top: 25px;
    background-color: lightgray;
  }
  .phone {
    display: flex;
    margin-top: 25px;
    background-color: lightgrey;
  }
  .contactForm {
    width: 100%;
    height: 784px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: none;
    border-radius: 12px;
    opacity: 1;
    padding-left: 60px;
  }

  form {
    margin-top: 47px;
  }

  input {
    width: 100%;
    height: 67px;
    /* UI Properties */
    box-shadow: none;
    border: 1px solid #a5a5a5;
    border-radius: 8px;
    opacity: 0.63;
    margin-top: 13px;
    padding: 21px;
  }

  textarea {
    width: 100%;
    height: 287px;
    /* UI Properties */
    box-shadow: none;
    border: 1px solid #a5a5a5;
    border-radius: 8px;
    opacity: 0.63;
    margin-top: 13px;
    padding: 21px;
  }

  .name {
    display: flex;
    flex-direction: column;
  }
  .email {
    display: flex;
    flex-direction: column;
  }
  .message {
    display: flex;
    flex-direction: column;
  }

  label {
    /* width: 58px;
      height: 20px; */
    /* UI Properties */
    text-align: left;
    font: normal normal bold 15px/20px ;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
    margin-top: 38px;
  }

  button {
    width: 353px;
    height: 53px;
    /* UI Properties */
    background: #008eff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    margin-top: 40px;
    color: white;
  }

  .information {
    border-radius: 8px;
    margin-top: 13px;
    padding: 21px;
    color: black;
    display: flex;
    justify-content: space-between;
    width: 90%;
  }

  .information h1 {
    max-width: 588px;
    min-height: 100px;
    /* UI Properties */
    text-align: left;
    font: normal normal bold 32px/40px ;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
  }

  .phone .info .street {
    margin-top: 40px;
  }

  .phone p {
    /* width: 165px;
      height: 27px; */
    /* UI Properties */
    text-align: left;
    font: normal normal normal 20px/27px ;
    letter-spacing: 0px;
    color: #a5a5a5;
    opacity: 1;
    margin-left: 10px;
    margin-top: 20px;
  }

  .info p {
    text-align: left;
    font: normal normal normal 20px/27px ;
    letter-spacing: 0px;
    color: #a5a5a5;
    opacity: 1;
    margin-left: 10px;
    margin-top: 20px;
  }

  .street p {
    text-align: left;
    font: normal normal normal 20px/27px ;
    letter-spacing: 0px;
    color: #a5a5a5;
    opacity: 1;
    margin-top: 20px;
    margin-left: 10px;
  }

  .map {
    /* margin-top: 68.12px; */
    /* width: 547px;
    height: 318px; */
    /* UI Properties */
    margin-left: 50px;
    opacity: 1;
    background-color: gray;
  }
  .all {
    margin-top: 46px;
  }
  .phoneIcon {
    width: 23px;
    height: 23px;
    /* UI Properties */
    /* background: white 0% 0% no-repeat padding-box; */
    /* border: 1px solid #000000; */
    opacity: 1;
    /* margin-top: 10px; */
    margin-top: 20px !important;
    margin-left: 10px;
  }

  .mailIcon {
    width: 30px;
    height: 20px;
    opacity: 1;
    margin-top: 20px !important;
    margin-left: 10px;
  }

  .mapIcon {
    width: 18px;
    height: 26px;
    /* UI Properties */
    /* background: #000000 0% 0% no-repeat padding-box;
      border: 1px solid #000000; */
    opacity: 1;
    margin-top: 20px !important;
    margin-left: 10px;
  }

  .mapp {
    width: 409px;
    height: 100%;
  }


  @media screen and (max-width: 1900px) {

    .hr{
      max-width: 1000px !important;
    height: 1px !important;
    background-color: grey;
    margin-top: 25px;
    margin-bottom: 25px;
    display: none;
    }
    .HR{
      margin-top: 15px;
    margin-bottom: 15px;
    }
    .information {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 1420px) {
    .all {
      display: flex;
      flex-direction: column;
    }
    .information {
      display: flex;
      justify-content: space-between;
    }
    .mapp {
      /* width: 1100px;
        height: 318px; */
    }
  }
  @media screen and (max-width: 1250px) {

    .hr{
      max-width: 800px !important;
    height: 1px !important;
    background-color: grey;
    margin-top: 15px;
    margin-bottom: 15px;
    display: none;
    }
    .all {
      display: flex;
      flex-direction: column;
    }
    .information {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .mapp {
      width: 100%;
      height: 318px;
    }

    .map {
      margin-left: 33px;
    }
    .contactForm {
      width: 100%;
      height: 750px;
      /* UI Properties */
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: none;
      border-radius: 12px;
      opacity: 1;
    }

    input {
      width: 100%;
      height: 62px;
      /* UI Properties */
      box-shadow: none;
      border: 1px solid #a5a5a5;
      border-radius: 8px;
      opacity: 0.63;
    }

    textarea {
      width: 100%;
      height: 266px;
      /* UI Properties */
      box-shadow: none;
      border: 1px solid #a5a5a5;
      border-radius: 8px;
      opacity: 0.63;
    }

    button {
      width: 50%;
      height: 50px;
      justify-content: center;
      /* UI Properties */
      background: #008eff 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
    }

    iframe {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    .all {
      display: flex;
      flex-direction: column;
    }
    .information {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .mapp {
      width: 900px;
      height: 318px;
    }
    .contactForm {
      width: 100%;
      height: 622px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: none;
      border-radius: 6px;
      opacity: 1;
    }

    input {
      width: 350px;
      height: 35px;
      box-shadow: none;
      border: 1px solid #a5a5a5;
      border-radius: 4px;
      opacity: 0.63;
    }

    textarea {
      width: 350px;
      height: 182px;
      box-shadow: none;
      border: 1px solid #a5a5a5;
      border-radius: 4px;
      opacity: 0.63;
    }

    button {
      width: 90%;
      height: 35px;
      background: #008eff 0% 0% no-repeat padding-box;
      border-radius: 3px;
      opacity: 1;
    }

    h1 {
      text-align: left;
      font: normal normal bold 20px/27px ;
      letter-spacing: 0px;
      color: #2b2b2b;
      opacity: 1;
    }
    .infoo {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .mapp {
      width: 100%;
    }
    .map {
      width: 100%;
      margin-left: 15px;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (max-width: 480px) {
    .texti p {

      text-align: left;
      font: normal normal bold 13pt/20pt ;
      letter-spacing: 0px;
      color: #141414;
      opacity: 1;
  }
    .information h1{

      text-align: left;
      font: normal normal bold 22px/30px ;
      letter-spacing: 0px;
      color: #141414;
      opacity: 1;
  }
    .phone {
      margin-top: 25px;
    }
    .all {
      display: flex;
      flex-direction: column;
    }
    .information {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .contactForm {
      width: 100%;
      height: 622px;
      /* UI Properties */
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: none;
      border-radius: 6px;
      opacity: 1;
    }

    input {
      width: 300px;
      height: 35px;
      /* UI Properties */
      box-shadow: none;
      border: 1px solid #a5a5a5;
      border-radius: 4px;
      opacity: 0.63;
    }

    textarea {
      width: 300px;
      height: 182px;
      /* UI Properties */
      box-shadow: none;
      border: 1px solid #a5a5a5;
      border-radius: 4px;
      opacity: 0.63;
    }

    button {
      width: 90%;
      height: 35px;
      /* UI Properties */
      background: #008eff 0% 0% no-repeat padding-box;
      border-radius: 3px;
      opacity: 1;
    }

    h1 {
      text-align: left;
      font: normal normal bold 19px/27px ;
      letter-spacing: 0px;
      color: #2b2b2b;
      opacity: 1;
    }
    .infoo {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .mapp {
      width: 100%;
    }
    .map {
      width: 100%;
      margin-left: 0px;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .phone p {
      /* width: 165px;
      height: 27px; */
      /* UI Properties */
      text-align: left;
      font: normal normal normal 18px/27px ;
      letter-spacing: 0px;
      color: #a5a5a5;
      opacity: 1;
    }
  }
  /* @media screen and (max-width: 992px) {
      .all {
        display: flex;
        flex-direction: column;
      }
      .information {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .mapp {
        width: 900px;
        height: 318px;
      }
    } */

  @media screen and (max-width: 415px) {
    .all {
      display: flex;
      flex-direction: column;
    }
    .information {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .mapp {
      width: 900px;
      height: 318px;
    }
    .contactForm {
      width: 100%;
      height: 622px;
      /* UI Properties */
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: none;
      border-radius: 6px;
      opacity: 1;
    }

    input {
      width: 300px;
      height: 35px;
      /* UI Properties */
      box-shadow: none;
      border: 1px solid #a5a5a5;
      border-radius: 4px;
      opacity: 0.63;
    }

    textarea {
      width: 300px;
      height: 182px;
      /* UI Properties */
      box-shadow: none;
      border: 1px solid #a5a5a5;
      border-radius: 4px;
      opacity: 0.63;
    }

    button {
      width: 90%;
      height: 35px;
      /* UI Properties */
      background: #008eff 0% 0% no-repeat padding-box;
      border-radius: 3px;
      opacity: 1;
    }

    h1 {
      text-align: left;
      font: normal normal bold 19px/27px ;
      letter-spacing: 0px;
      color: #2b2b2b;
      opacity: 1;
    }
    .infoo {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .mapp {
      width: 350px;
    }
    .map {
      width: 300px;
      margin-left: 0px;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .phone p {
      /* width: 165px;
      height: 27px; */
      /* UI Properties */
      text-align: left;
      font: normal normal normal 18px/27px ;
      letter-spacing: 0px;
      color: #a5a5a5;
      opacity: 1;
    }
  }
  @media screen and (max-width: 390px) {
    .all {
      display: flex;
      flex-direction: column;
    }
    .information {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .mapp {
      width: 100%;
      height: 318px;
    }
    .contactForm {
      width: 100%;
      height: 622px;
      /* UI Properties */
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: none;
      border-radius: 6px;
      opacity: 1;
    }

    input {
      width: 240px;
      height: 35px;
      /* UI Properties */
      box-shadow: none;
      border: 1px solid #a5a5a5;
      border-radius: 4px;
      opacity: 0.63;
    }

    textarea {
      width: 240px;
      height: 182px;
      /* UI Properties */
      box-shadow: none;
      border: 1px solid #a5a5a5;
      border-radius: 4px;
      opacity: 0.63;
    }

    button {
      width: 90%;
      height: 35px;
      /* UI Properties */
      background: #008eff 0% 0% no-repeat padding-box;
      border-radius: 3px;
      opacity: 1;
    }

    h1 {
      text-align: left;
      font: normal normal bold 20px/27px ;
      letter-spacing: 0px;
      color: #2b2b2b;
      opacity: 1;
    }
    .infoo {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .mapp {
      /* width: 300px; */
    }
    .map {
      width: 100%;
      margin-left: 0px;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .phone p {
      /* width: 165px;
      height: 27px; */
      /* UI Properties */
      text-align: left;
      font: normal normal normal 18px/27px ;
      letter-spacing: 0px;
      color: #a5a5a5;
      opacity: 1;
    }
  }
  </style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticStyle:{"padding":"80px 15px 15px 145px","padding-top":"180px","background-color":"transparent"}},[_c('div',[_c('div',{staticClass:"all",staticStyle:{"margin-top":"0px"}},[_c('div',{staticClass:"information"},[_c('div',{staticClass:"infoo"},[_c('div',[_c('div',[_vm._m(0),_vm._m(1),_c('div',{staticClass:"container info"},[_c('b-icon',{staticClass:"mailIcon",staticStyle:{"color":"black"},attrs:{"icon":"envelope-fill","aria-hidden":"true"}}),_vm._m(2)],1)]),_c('div',[_c('div',{staticClass:"container street"},[_c('b-icon',{staticClass:"mapIcon",attrs:{"icon":"link","aria-hidden":"true"}}),_vm._m(3)],1)]),_c('div',{staticClass:"container phone"},[_c('b-icon',{staticClass:"phoneIcon",attrs:{"icon":"telephone-fill","aria-hidden":"true"}}),_vm._m(4),_vm._m(5)],1),_c('div',{staticClass:"container phone"},[_c('b-icon',{staticClass:"phoneIcon",attrs:{"icon":"globe","aria-hidden":"true"}}),_vm._m(6),_vm._m(7)],1)])]),_vm._m(8),_c('div',{staticClass:"infoo"},[_c('div',[_c('div',[_vm._m(9),_vm._m(10),_c('div',[_c('div',{staticClass:"container info"},[_c('b-icon',{staticClass:"mailIcon",staticStyle:{"color":"black"},attrs:{"icon":"envelope-fill","aria-hidden":"true"}}),_vm._m(11)],1)]),_c('div',[_c('div',{staticClass:"container street"},[_c('b-icon',{staticClass:"mapIcon",attrs:{"icon":"link","aria-hidden":"true"}}),_vm._m(12)],1)]),_c('div',{staticClass:"container phone"},[_c('b-icon',{staticClass:"phoneIcon",attrs:{"icon":"telephone-fill","aria-hidden":"true"}}),_vm._m(13)],1),_c('div',{staticClass:"container phone"},[_c('b-icon',{staticClass:"phoneIcon",attrs:{"icon":"globe","aria-hidden":"true"}}),_vm._m(14)],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"biphoto"},[_c('img',{attrs:{"src":require("@/assets/images/digiti2.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container texti"},[_c('h1',[_vm._v("DigIT Consulting")]),_c('p',[_vm._v(" At DigIT Consulting, we're more than a SaaS platform; we're your partner in success. Our mission is to provide innovative tools and solutions that transform your business. We prioritize simplicity, reliability, and a customer-centric approach, ensuring your business thrives in the digital age. Trust us to transform the way you work. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"mailto:contacts@digitbusiness.ch"}},[_vm._v("contacts@digitbusiness.ch")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"https://www.digitbusiness.ch/","target":"_blank"}},[_vm._v("https://www.digitbusiness.ch/")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black","margin-right":"15px"},attrs:{"href":"tel:+41 79 336 31 36"}},[_vm._v("CH: +41 79 336 31 36")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"tel:+383 49 999 433"}},[_vm._v("XK: +383 49 999 433")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black","margin-right":"5px"},attrs:{"href":"https://www.google.com/maps/place/DigIT+Consulting+GmbH/@47.3664979,8.5179592,17z/data=!3m1!4b1!4m6!3m5!1s0x479009e402ed904b:0x64f06f7a3f8075c4!8m2!3d47.3664943!4d8.5205341!16s%2Fg%2F11t314x8nq?entry=ttu","target":"_blank"}},[_vm._v(" HQ - Steinstrasse 56, 8003 Zürich")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"https://www.google.com/maps/place/28a+Rexhep+Mala,+Prishtina+10000/@42.6550361,21.1626988,17z/data=!3m1!4b1!4m5!3m4!1s0x13549ee804a7a7e5:0x9605bc885cd33733!8m2!3d42.6550322!4d21.1648875?shorturl=1","target":"_blank"}},[_vm._v(" Branch - R. Mala, 28A, Prishtinë")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"HR"},[_c('div',{staticClass:"hr"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"biphoto"},[_c('img',{attrs:{"src":require("@/assets/images/Walone.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container texti"},[_c('h1',[_vm._v("Walone FASHION GROUP L.L.C.")]),_c('p',[_vm._v(" Walone Fashion Group, based in Kosovo, make male and female apparel and have garnered a great reputation across Europe. Whilst these are gowns that every lady will admire, it is worthy to note that the brand makes every clothing item. Their gowns have been worn by Albanian singers and superstars across the continent. They create a slew of wonderful dresses regularly, as such, their style pushes barriers and is continually funky. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"mailto:walonefashion@outlook.com"}},[_vm._v("walonefashion@outlook.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"https://walonefashion.com/","target":"_blank"}},[_vm._v("https://walonefashion.com/")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"tel:+38349200440"}},[_vm._v("+383 49 200 440")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"https://maps.app.goo.gl/azzc1eHdsspWmkb18","target":"_blank"}},[_vm._v("Çagllavicë Magjistralja PRISHTINË-FERIZAJ, KM7, 10000, Prishtina, Kosovo")])])
}]

export { render, staticRenderFns }
<template>
  <div>
    <InfoDetails v-if="client==='Development'" />
    <InfoDetailsXharra v-if="client==='DrenushaXharra'" />
    <InfoDetailsNila v-if="client==='FjollaNila'" />
    <InfoDetailsVS v-if="client==='ValdrinSahiti'" />
    <InfoDetailsWalone v-if="client==='WaloneFashion'" />
    <InfoDetailsMina v-if="client==='MinnaFashion'" />
    <InfoDetailsNota v-if="client==='NotaBrillant'" />
  </div>
</template>

<script>

import InfoDetails from '@/components/infoPages/InfoDetails.vue'
import InfoDetailsXharra from '@/components/infoPages/InfoDetailsXharra.vue'
import InfoDetailsNila from '@/components/infoPages/InfoDetailsNila.vue'
import InfoDetailsVS from '@/components/infoPages/InfoDetailsVS.vue'
import InfoDetailsWalone from '@/components/infoPages/InfoDetailsWalone.vue'
import InfoDetailsMina from '@/components/infoPages/InfoDetailsMina.vue'
import InfoDetailsNota from '@/components/infoPages/InfoDetailsNota.vue'
import { client } from '../domainConfig'



export default {
  components: {
    InfoDetails, InfoDetailsXharra, InfoDetailsNila, InfoDetailsVS, InfoDetailsWalone, InfoDetailsMina, InfoDetailsNota,
  },
  data() {
    return {
      client: client.clientName,
    }
  },
}


</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticStyle:{"padding":"0px 15px 15px 145px","padding-top":"80px","background-color":"transparent"}},[_c('div',[_c('div',{staticClass:"all",staticStyle:{"margin-top":"0px"}},[_c('div',{staticClass:"information"},[_c('div',{staticClass:"infoo"},[_c('div',[_c('div',[_vm._m(0),_vm._m(1),_c('div',[_c('div',{staticClass:"container info"},[_c('b-icon',{staticClass:"mailIcon",staticStyle:{"color":"black"},attrs:{"icon":"envelope-fill","aria-hidden":"true"}}),_vm._m(2)],1)]),_c('div',[_c('div',{staticClass:"container street"},[_c('b-icon',{staticClass:"mapIcon",attrs:{"icon":"link","aria-hidden":"true"}}),_vm._m(3)],1)]),_c('div',{staticClass:"container phone"},[_c('b-icon',{staticClass:"phoneIcon",attrs:{"icon":"telephone-fill","aria-hidden":"true"}}),_vm._m(4),_vm._m(5)],1),_c('div',{staticClass:"container phone"},[_c('b-icon',{staticClass:"phoneIcon",attrs:{"icon":"globe","aria-hidden":"true"}}),_vm._m(6),_vm._m(7)],1)])])]),_vm._m(8),_c('div',{staticClass:"infoo"},[_c('div',[_c('div',[_vm._m(9),_vm._m(10),_c('div',[_c('div',{staticClass:"container info"},[_c('b-icon',{staticClass:"mailIcon",staticStyle:{"color":"black"},attrs:{"icon":"envelope-fill","aria-hidden":"true"}}),_vm._m(11)],1)]),_c('div',[_c('div',{staticClass:"container street"},[_c('b-icon',{staticClass:"mapIcon",attrs:{"icon":"link","aria-hidden":"true"}}),_vm._m(12)],1)]),_c('div',{staticClass:"container phone"},[_c('b-icon',{staticClass:"phoneIcon",attrs:{"icon":"telephone-fill","aria-hidden":"true"}}),_vm._m(13)],1),_c('div',{staticClass:"container phone"},[_c('b-icon',{staticClass:"phoneIcon",attrs:{"icon":"globe","aria-hidden":"true"}}),_vm._m(14)],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"biphoto"},[_c('img',{attrs:{"src":require("@/assets/images/digiti2.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container texti"},[_c('h1',[_vm._v("DigIT Consulting")]),_c('p',[_vm._v(" At DigIT Consulting, we're more than a SaaS platform; we're your partner in success. Our mission is to provide innovative tools and solutions that transform your business. We prioritize simplicity, reliability, and a customer-centric approach, ensuring your business thrives in the digital age. Trust us to transform the way you work. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"mailto:contacts@digitbusiness.ch"}},[_vm._v("contacts@digitbusiness.ch")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"https://www.digitbusiness.ch/","target":"_blank"}},[_vm._v("https://www.digitbusiness.ch/")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black","margin-right":"15px"},attrs:{"href":"tel:+41 79 336 31 36"}},[_vm._v("CH: +41 79 336 31 36")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"tel:+383 49 999 433"}},[_vm._v("XK: +383 49 999 433")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black","margin-right":"5px"},attrs:{"href":"https://www.google.com/maps/place/DigIT+Consulting+GmbH/@47.3664979,8.5179592,17z/data=!3m1!4b1!4m6!3m5!1s0x479009e402ed904b:0x64f06f7a3f8075c4!8m2!3d47.3664943!4d8.5205341!16s%2Fg%2F11t314x8nq?entry=ttu","target":"_blank"}},[_vm._v(" HQ - Steinstrasse 56, 8003 Zürich")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"https://www.google.com/maps/place/28a+Rexhep+Mala,+Prishtina+10000/@42.6550361,21.1626988,17z/data=!3m1!4b1!4m5!3m4!1s0x13549ee804a7a7e5:0x9605bc885cd33733!8m2!3d42.6550322!4d21.1648875?shorturl=1","target":"_blank"}},[_vm._v(" Branch - R. Mala, 28A, Prishtinë")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"HR"},[_c('div',{staticClass:"hr"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"biphoto"},[_c('img',{staticStyle:{"max-width":"350px"},attrs:{"src":require("@/assets/images/notaFull.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container texti"},[_c('h1',[_vm._v("Nota brillant")]),_c('p',[_vm._v(" NOTAbrillant has been a trusted name in the home decor industry since 1966. With a rich heritage spanning over five decades, we have established ourselves as a premier destination for premium quality carpets and drapes. Our commitment to craftsmanship, customer satisfaction and timeless design has made us a household name for generations. Founded in 1966, NOTAbrillant started as a small family business in Gjilan. Over the years, our commitment to excellence and an unwavering focus on customer needs have led to steady growth. Today, we are proud to have four locations strategically located throughout Kosovo, serving a diverse clientele. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"mailto: kujdesi@nota-brillant.com"}},[_vm._v(" kujdesi@nota-brillant.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"https://nota-brillant.com/","target":"_blank"}},[_vm._v("nota-brillant.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":"tel:+38344503399"}},[_vm._v("+383 44 503 399")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"black"},attrs:{"href":""}},[_vm._v("Magjistralja Prishtinë-Ferizaj, 3, Lipjan 14000")])])
}]

export { render, staticRenderFns }